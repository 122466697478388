ul > li {
    flex-wrap: wrap;
    padding: 0px 2vw;
}

.navDark {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    /* color: rgba(51, 86, 127, 1) */
    transition: color 1s ease-in;
}

a:hover {
    color: rgba(255,169,27,1);
}

.navbar {
    z-index:99;
}

.custom-nav-btn {
    text-transform: uppercase;
    font-family: 'Abadi MT Std', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}

.custom-dropdown-menu {
    border-radius: 15px;
}

.custom-dropdown-item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: black;
    transition: width .3s;
}

.custom-dropdown-item:hover::after {
    width: 100%;
    transition: width .3s;
}

@media only screen and (max-width: 767px) {
    .navbar-collapse {
        background: rgba(0, 0, 0, 0.85);
        border-radius: 15px;
    }

    .navDark {
        color: rgba(255, 255, 255, 1);
        font-weight: 400;
    }
}