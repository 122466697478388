.landingpage .bg-cover {
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0)), url(../../image/frontPageBackground.jpg) no-repeat center center fixed;  
    background-size: cover;
}

.custom-btn {
    text-transform: uppercase;
    font-family: 'Abadi MT Std', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(216, 216, 216, 1);
    background-color: rgba(0, 0, 0, 0.7);
    border-color: transparent;
    border-radius: 25px;
    width: 250px;
    height: 35px;
    margin-top: 20px;
    transition: background-color 1s ease-in;
}

.custom-btn:hover {
    background-color: rgba(255, 255, 255, 1);
    color: black;
}

#text {
    color: #ffffff;
    text-align: center;
    text-shadow: 0 0 16px rgba(0,0,0,1);
    position: absolute;
    top: 30vh;
    font-family: 'Abadi MT Std', sans-serif;
    font-size: 8vh;
    font-weight: 200;
}

#custom-button-bar {
    position: absolute;
    bottom: 10vh;
    width: 100%;
}